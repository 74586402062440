import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ProductsList from "./productsList"

const ProductsListContainer = () => (
  <StaticQuery
    query={graphql`
      query ProductsListQuery {
        allContentfulProducts {
          nodes {
            slug
            name
            lookTags
            typeTags
            modelNumber
            seriesSlug
            coverImage {
              fluid(maxWidth: 4000,quality:100) {
                src
              }
            }
            colors {
              id
              fluid(maxWidth: 1920) {
                src
              }
            }
          }
        }
      }
    `}
    render={data => {
      const products = data.allContentfulProducts.nodes
      // console.log("what is in props", products)
      return (
        <div className='homepage'>
          <ProductsList allProducts={products} />
        </div>
      )
    }}
  />

)

export default ProductsListContainer
