import React, { Fragment } from "react"
import ProductsBanner from "../components/products/productsBanner"
import ProductsListContainer from "../components/products/productsListContainer"
import Footer from "../components/common/footer"
import "semantic-ui-css/semantic.min.css"
import "../styles/index.css"
import { Helmet } from 'react-helmet';  // Import Helmet

const Products = () => {
  return (
    <Fragment>
        <Helmet>
                <title>Products - DP Tile and Stone</title>
                <meta name="description" content={`Explore all our products`} />
            </Helmet>
      <ProductsBanner/>
      <ProductsListContainer />
      <Footer />
    </Fragment>
  )
}
export default Products
