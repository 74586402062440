import React from "react"
import _ from "lodash"
import {
  Grid,
  Image,
  Card,
  Search,
  Pagination,
  Divider,
  List,
  Checkbox,
  Icon
} from "semantic-ui-react"
import { Link } from "gatsby"
import {
  fetchAllProducts,
  changeProductsPage,
  collectLookfilterResults,
  collectTypefilterResults,
  collectSearchResults
} from "../../state/app"
import { connect } from "react-redux"

import closeb from "../../images/closeb.png"
import search from "../../images/search.png"

const initialState = {
  isLoading: false,
  results: [],
  lookFilter: [],
  typeFilter: [],
  value: "",
  activePage: 1,
  onFilter: false,
  onSearch: false,
  filterProducts: []
}

const lookList = [
  "Marble",
  "Concrete",
  "Wood",
  "Metal",
  "Stone",
  "Fabric",
  "Mosaic",
]

const typeList = [
  "Kitchen",
  "Bathroom",
  "Living area",
  "Sleeping area",
  "Outdoor",
  "Commercial",
  "Pools and wellness",
]

class ProductsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState

    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleFilterClick = this.handleFilterClick.bind(this)
    this.handleSearchMenuClose = this.handleSearchMenuClose.bind(this)
    this.handleFilterMenuClose = this.handleFilterMenuClose.bind(this)

  }
  componentDidMount() {
    this.props.fetchAllProducts(this.props.allProducts)
    this.setState({ activePage: this.props.products.productsPage })
    console.log('what is all products', this.props.allProducts)

  }
  //this.props.allProducts from productListContainer.js
  //this.props.products = redux.state


  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.description })
  }
  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), "i")

      const isMatch = result => {
        //result是遍历所有product, 是从_filter 来的
        return re.test(`${result.modelNumber},${result.name}`)
      }

      let filterProducts = _.filter(this.props.allProducts, isMatch).map(
        product => {
          return {
            key: product.slug,
            title: product.name,
            description: product.modelNumber,
            image: product.coverImage.fluid.src,
          }
        }
      )

      this.setState({
        isLoading: false,
        results: filterProducts,
        activePage: 1
      })
      this.props.collectSearchResults(this.state.value)
    }, 100)
    console.log('test', this.props.products.searchResults)
  }

  async handleChangePanigation(event, data) {
    this.props.changeProductsPage(data.activePage)
    await this.setState({ activePage: data.activePage })
  }

  lookFilterToggle = item => {
    let lookFilter = this.state.lookFilter
    let index = lookFilter.indexOf(item)
    if (index !== -1) {
      lookFilter.splice(index, 1)
    } else {
      lookFilter.push(item)
    }
    this.setState(lookFilter)
    this.props.collectLookfilterResults(lookFilter)
  }

  typeFilterToggle = item => {
    let typeFilter = this.state.typeFilter
    let index = typeFilter.indexOf(item)
    if (index !== -1) {
      typeFilter.splice(index, 1)
      //when uncheck item, remove item from list arrary 
    } else {
      typeFilter.push(item)
      //when check new item, index === -1
    }
    this.setState(typeFilter)
    this.props.collectTypefilterResults(typeFilter)
  }

  handleSearchClick() {
    this.setState({
      onSearch: true
    })
  }

  handleFilterClick() {
    this.setState({
      onFilter: true
    })
  }

  handleSearchMenuClose() {
    this.setState({
      onSearch: false
    })
  }

  handleFilterMenuClose() {
    this.setState({
      onFilter: false
    })
  }


  render() {
    const {
      isLoading,
      value,
      results,
      lookFilter,
      typeFilter,
      activePage
    } = this.state

    let filterProducts = this.props.allProducts

    if (this.props.products.lookFilterResults.length) {
      filterProducts = filterProducts.filter(product => {
        if (product.lookTags) {
          if (product.lookTags.some(r => this.props.products.lookFilterResults.includes(r))) {
            return product
          } else {
            return null
          }
        } else return null
      })
    }

    if (this.props.products.typeFilterResults.length) {
      filterProducts = filterProducts.filter(product => {
        if (product.typeTags) {
          if (product.typeTags.some(r => this.props.products.typeFilterResults.includes(r))) {
            return product
          } else {
            return null
          }
        } else return null
      })
    }

    if (this.props.products.searchResults) {
      const re = new RegExp(_.escapeRegExp(this.props.products.searchResults), "i")
      const isMatch = result => re.test(`${result.modelNumber},${result.name}`)
      filterProducts = _.filter(filterProducts, isMatch)
    }

    function compare(a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    }

    const products = filterProducts.sort(compare).slice(activePage * 9 - 9, activePage * 9)

    //这里products是空的因为，activepage 不对

    return (
      <Grid divided="vertically">
        <Grid.Row className="pc-productlist-layout">
          <Grid.Column width={5}>
            <div className="products-search">
              <Search
                input={{ iconPosition: "left" }}
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true,
                })}
                results={results}
                value={value}
                placeholder="Search"
              // {...this.props}
              />
              <Divider />
              <div className="custom-filter">
                <p>Look</p>
                <List>
                  {lookList.map(look => (
                    <List.Item key={look}>
                      <Checkbox
                        label={look}
                        onChange={() => this.lookFilterToggle(look)}
                        checked={lookFilter.includes(look)}
                      />
                    </List.Item>
                  ))}
                </List>
              </div>
              <div>
                <p>Space</p>
                <List>
                  {typeList.map(type => (
                    <List.Item key={type}>
                      <Checkbox
                        label={type}
                        onChange={() => this.typeFilterToggle(type)}
                        checked={typeFilter.includes(type)}
                      />
                    </List.Item>
                  ))}
                </List>
              </div>
            </div>
          </Grid.Column>

          <Grid.Column width={11} className="products-list">
            <Card.Group style={{ width: "100%" }}>
              {products.map(product => {
                const link = `/products/${product.slug}`

                return (
                  <Link to={link} key={product.slug} style={{ width: "33%" }}>

                    <Card>
                      <Image
                        src={product.coverImage.fluid.src}
                        wrapped
                        ui={false}
                        className="coverImage"
                      />
                      <Card.Content style={{ borderTop: 'none' }}>
                        <Card.Header>{product.name}</Card.Header>
                        <Card.Meta>{product.modelNumber}</Card.Meta>
                        <Card.Description>
                          <div>
                            {(product.colors || []).map(color => (
                              <span className="color-circle" key={color.id}>
                                <Image src={color.fluid.src} ui={false} />
                              </span>
                            ))}
                          </div>
                        </Card.Description>


                      </Card.Content>
                    </Card>
                  </Link>
                )
              })}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>

        {/* Display only in Mobile View */}

        {this.state.onSearch && <div className="search-menu-container">
          <div className="menu">
            <i onClick={this.handleSearchMenuClose}><Image src={closeb} className="close-icon" style={{ marginTop: "0px" }} /></i>

            <div className="search-container">
              <Search
                input={{}}
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true,
                })}
                icon={false}
                results={results}
                value={value}
                placeholder="Search for products"
              // {...this.props}
              />
            </div>

            {/* <div className="search-container">
                    <h6>Popular searches</h6>
                </div> */}
          </div>
        </div>}

        {this.state.onFilter && <div className="search-menu-container">
          <div className="menu">
            <i onClick={this.handleFilterMenuClose}><Image src={closeb} className="close-icon" /></i>

            <div className="filter-container">
              <h3>Filter</h3>
            </div>

            <div className="look-container">
              <h6>Look</h6>
              <List>
                {lookList.map(look => (
                  <List.Item key={look}>
                    <Checkbox
                      label={look}
                      onChange={() => this.lookFilterToggle(look)}
                      checked={lookFilter.includes(look)}
                    />
                  </List.Item>
                ))}
              </List>
            </div>

            <div className="type-container">
              <h6>Space</h6>
              <List>
                {typeList.map(type => (
                  <List.Item key={type}>
                    <Checkbox
                      label={type}
                      onChange={() => this.typeFilterToggle(type)}
                      checked={typeFilter.includes(type)}
                    />
                  </List.Item>
                ))}
              </List>
            </div>
          </div>
        </div>}

        <Grid.Row className="mobile-productlist-layout products-list">
          <Grid.Column width={11} className="mobile-search-container">
            <button onClick={this.handleSearchClick}>
              Search
              <Image src={search}></Image>
            </button>
          </Grid.Column>

          <Grid.Column width={5} className="mobile-filter-container">
            <button onClick={this.handleFilterClick}>
              Filter
            </button>
          </Grid.Column>

          <Card.Group style={{ width: "100%" }}>
            {products.map(product => {
              const link = `/products/${product.slug}`

              return (
                <Link to={link} key={product.slug} style={{ width: "100%" }}>
                  <Card>
                    <Image
                      src={product.coverImage.fluid.src}
                      wrapped
                      ui={false}
                      className="coverImage"
                    />
                    <Card.Content style={{ borderTop: 'none' }}>
                      <Card.Header>{product.name}</Card.Header>
                      <Card.Meta>{product.modelNumber}</Card.Meta>
                      <Card.Description>
                        <div>
                          {(product.colors || []).map(color => (
                            <span className="color-circle" key={color.id}>
                              <Image src={color.fluid.src} ui={false} />
                            </span>
                          ))}
                        </div>
                        <span></span>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Link>
              )
            })}
          </Card.Group>
        </Grid.Row>

        <div className="paginationContainer">
          <Pagination
            boundaryRange={0}
            activePage={activePage}
            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: <Icon name='angle left' />, icon: true }}
            nextItem={{ content: <Icon name='angle right' />, icon: true }}
            siblingRange={1}
            totalPages={Math.ceil(filterProducts.length / 9)}
            onPageChange={(event, data) =>
              this.handleChangePanigation(event, data)
            }
          />
        </div>
      </Grid>
    )
  }
}


export default connect(
  ({ products, lookFilterResults, typeFilterResults, searchResults }) => ({ products, lookFilterResults, typeFilterResults, searchResults }),
  { fetchAllProducts, changeProductsPage, collectLookfilterResults, collectTypefilterResults, collectSearchResults }
)(ProductsList)
