import React from "react"
import { Segment, Header } from "semantic-ui-react"
import { StaticQuery, graphql } from "gatsby"
import HeaderComponent from "../common/header"

const ProductsBanner = () => (
  <StaticQuery
    query={graphql`
      query productsPageBannerQuery {
        contentfulOtherPages {
          productsPageBannerImage {
            fluid(maxWidth: 4000,quality:100) {
              src
            }
          }
        }
      }
    `}
    render={data => {
      const productsPage = data.contentfulOtherPages

      return (
        <Segment
          inverted
          textAlign="center"
          vertical
          style={{
            background: `url(${productsPage.productsPageBannerImage.fluid.src}) no-repeat center center`,
            backgroundSize: "cover",
            height: "450px",
          }}
          className="productsPage-banner"
        >
          <HeaderComponent page="products"/>
          <Header as="h1" content="Products" inverted />
        </Segment>
      )
    }}
  />
)

export default ProductsBanner
